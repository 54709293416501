import React from 'react'
import Text from '../../components/common/Text'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import Breadcrumb from '../../components/common/Breadcrumb'
import { useTranslation } from 'react-i18next'
import IconPhone from '../../assets/images/base/icons-phone-navigation-black.svg'
import IconEmail from '../../assets/images/base/icons-mail-navigation-black.svg'
import IconHome from '../../assets/images/base/icons-home-navigation-black.svg'
import { mobile, useScreen } from '../../utils/responsive-helper'

const BreadcrumbLayout = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  ${mobile} {
    margin : 24px 16px;
  }
`

const ContentLayout = styled.div`
  background-color: ${color.WHITE};
  // height: 100%;
  margin-bottom: 10px;
  padding: 32px;
  border-radius: 8px;
`

const CenterLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  /* border: 1px solid #000000; */
  margin-bottom: 24px;
`

const WhiteLine = styled.div`
  height: 20px;
`

const Contact = () => {
  const { t } = useTranslation()
  const { isMobile } = useScreen()

  return (
    <>
      <BreadcrumbLayout>
        <Breadcrumb
          overide={(paths) => {
            paths = [
              { label: 'Home', path: '/' },
              { label: t('contact.header'), path: '/contact' },
            ]
            return paths
          }}
        />
      </BreadcrumbLayout>
      <CenterLayout>
        <Text size={28} type="Bold">
          {t('contact.header')}
        </Text>
      </CenterLayout>
      <ContentLayout>
        <Text size={24} type="Bold">
          ดิจิทัล แอสเซ็ท แมเนจเมนท์
        </Text>
        <WhiteLine />
        <div
          style={{
            borderWidth: 0,
            borderColor: color.BLACK,
            borderStyle: 'solid',
            display: 'grid',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              marginBottom: 16,
            }}
          >
            <img src={IconPhone} style={{ marginRight: 8 }} alt="phone icon" />
            <Text size={20} color={color.FONT_LIGHT_GRAY} type="Medium">
              {t('contact.phoneLabel')} :{' '}
            </Text>
            <Text size={20} style={{ marginLeft: 8 }} type="Medium">
              โทร 093-565-8748 วันจันทร์-ศุกร์ เวลา 9.00-17.00 น.
            </Text>
          </div>
        </div>
        <div
          style={{
            borderWidth: 0,
            borderColor: color.BLACK,
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 16,
          }}
        >
          <img src={IconEmail} style={{ marginRight: 8 }} alt="email icon" />
          <Text size={20} color={color.FONT_LIGHT_GRAY} type="Medium">
            {t('contact.emailLabel')} :{' '}
          </Text>
          <Text size={20} style={{ marginLeft: 8 }} type="Medium">
            flexclaimsure@insurepedia.co.th
          </Text>
        </div>

        <div
          style={{
            borderWidth: 0,
            borderColor: color.BLACK,
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 16,
          }}
        >
          <img src={IconEmail} style={{ marginRight: 8 }} alt="email icon" />
          <Text size={20} color={color.FONT_LIGHT_GRAY} type="Medium">
            {t('contact.addressLabel')} :
          </Text>
          <Text size={20} style={{ marginLeft: 8 }} type="Medium">
            179/111 อาคารบางกอกซิตี้ ทาวเวอร์ ช้ันที่ 26 ถนนสาทรใต้ แขวงทุ่งมหาเมฆ เขตสาทร กรุงเทพมหานคร 10120{' '}
          </Text>
        </div>

      </ContentLayout>
    </>
  )
}

export default Contact
