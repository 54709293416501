import styled from '@emotion/styled'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
// import IconSuccess from '../../assets/images/base/infographic-reward-success.svg'/
import IconSuccess from '../../assets/images/base/reward-success.png'

import FooterContent from '../../components/FooterContent'
import Button from '../../components/common/Button'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { main } from '../../constant/path'
import { numberWithCommas, useRouter } from '../../utils/helper'
import { mobile, useScreen } from '../../utils/responsive-helper'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const RowCenter = styled(Row)`
  text-align: center;
  justify-content: center;
`

const RowSpaceBetween = styled(Row)`
  margin-top: 24px;
  justify-content: space-between;
`

const LayoutImage = styled(RowCenter)`
  width: 100%;
  height: 120px;

  ${mobile} {
    height: 100px;
  }
`

const LayoutCard = styled(Card)`
  padding: 32px;
  margin-bottom: 80px;

  ${mobile} {
    padding: 16px;
    margin-bottom: 40px;
  }
`

const PaddingText = styled.div`
  padding-top: 8px;
  padding-bottom: 16px;
`
const PaddingSubText = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;
`

const PaddingImage = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`

const TextNotWrap = styled(Text)`
  white-space: nowrap;
`

const MarginTop = styled.div`
  margin-top: 64px;

  ${mobile} {
    margin-top: 24px;
  }
`

const ButtonStyle = styled(Button)`
  width: 267px;
`

const EClaimComplete = () => {
  const { t, i18n } = useTranslation()
  const { eClaimName, eClaimPrice, eClaimNumber, eClaimNameEn } = useRouter().query
  const { isMobile } = useScreen()
  const history = useHistory()
  const onClickBackToEnrollment = useCallback(() => {
    history.push(main())
  }, [history])

  return (
    <>
      <Column>
        <MarginTop />
        <RowCenter>
          <Text size={26} type={'Bold'} color={color.FONT_BLACK}>
            {' '}
            {t('eClaim.info.submitted')}
            {' '}
          </Text>
        </RowCenter>

        <PaddingImage>
          <LayoutImage>
            <Image src={IconSuccess} />
          </LayoutImage>
        </PaddingImage>
        <PaddingText>
          <RowCenter>
            <Text size={20} color={color.FONT_BLACK}>
              {' '}
              {t('app.orderNumber')} {eClaimNumber}{' '}
            </Text>
          </RowCenter>
        </PaddingText>

        <PaddingSubText>
          <RowCenter>
            <Text size={20} color={color.FONT_BLACK}>
              {' '}
              {
                eClaimName !== 'เงินกู้ฉุกเฉิน' &&
                (
                  <>
                    {t('eClaim.context.first')}<br />
                    {t('eClaim.context.second')} <br />
                    {t('eClaim.context.third')} <br />
                  </>
                )
              }
              <Text size={20} color={color.PRIMARY}>

                {
                  [
                    'ค่าแว่นตาและคอนแทคเลนส์',
                    'ค่าสมาชิกฟิตเนส/ค่าครูฝึกส่วนบุคคลในฟิตเนส',
                    'ค่ากิจกรรมกีฬา',
                    'ค่าประกันสุขภาพส่วนบุคคล (ประกัน FWD)',
                    'ค่าอุปกรณ์การแพทย์ที่ใช้ในครอบครัว',
                    'ค่าอุปกรณ์กีฬาต่างๆ',
                    'ค่าชุดตรวจหาเชื้อโควิด-19',
                    'ค่าการรักษาโดยแพทย์ทางเลือก',
                    'ค่าการรักษาโดยแพทย์ด้านสุขภาพจิต',
                    'ค่ารักษาโดยแพทย์ที่ประกันไม่ครอบคลุ',
                    'ค่าวัคซีนโควิด-19 หรือ วัคซีนชนิดอื่นๆ',
                    'ค่าตรวจสุขภาพ',
                    'ค่าคลอดบุตร',
                    'แท้งบุตร',
                    'การผ่าตัดแปลงเพศ'
                  ].includes(eClaimName) && `${t('eClaim.context.fourth')}`
                }

                {
                  [
                    'เงินช่วยเหลือค่าพวงหรีดงานศพ',
                    'ค่ากระเช้าเยี่ยมไข้พนักงานที่โรงพยาบาล'
                  ].includes(eClaimName) && `${t('eClaim.context.seventh')}`
                }

                {eClaimName === 'เงินกู้ฉุกเฉิน' && (
                  <>
                    {t('eClaim.context.fifth')} <br />
                    {t('eClaim.context.sixth')}
                  </>
                )}

              </Text>
            </Text>
          </RowCenter>
        </PaddingSubText>
        <LayoutCard>
          <Column>
            <Row>
              <Text size={24} type={'Bold'} color={color.FONT_BLACK}>
                {t('app.titleEclaim')}{' '}
              </Text>
            </Row>
            <RowSpaceBetween>
              <Text size={20} type={'Medium'} color={color.FONT_BLACK}>
                {i18n.language === 'en' ? eClaimNameEn : eClaimName}
              </Text>
              <TextNotWrap size={20} type={'Bold'} color={color.FONT_BLACK}>
                {numberWithCommas(eClaimPrice)} ฿
              </TextNotWrap>
            </RowSpaceBetween>
          </Column>
        </LayoutCard>
      </Column>
      <FooterContent
        style={{
          height: 80,
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-end',
          alignItems: 'center',
        }}
      >
        {<ButtonStyle onClick={onClickBackToEnrollment}>{t('eClaim.homeButton')}</ButtonStyle>}
      </FooterContent>
    </>
  )
}

export default EClaimComplete
