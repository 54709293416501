import { CSSProperties, useMemo } from 'react'
import styled from '@emotion/styled'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import Image from './Image'
import Text from './Text'
import { Carousel } from 'antd'
import { CarouselProps } from 'antd/lib/carousel'
import color from '../../constant/colors'
import { mobile } from '../../utils/responsive-helper'
import config from '../../config'
import defaultNews from '../../assets/images/base/default_news.jpg'

const SlideLayout = styled.div <{
  dotColor?: string
}>`
  width: 100%;
  margin-bottom: 40px;

  .swiper-wrapper {
    height: calc(100% - 48px);
    padding-bottom: 48px;
  }
  .swiper-pagination-bullet-active {
    width: 24px;
    border-radius: 8px;
    background: ${({ dotColor }) => dotColor || color.PRIMARY};
  }

  .image {
    z-index: inherit;
    margin-bottom: 16px;
  }

  ${mobile} {
    .swiper-slide {
      width: 100% !important;
    }
  }
`

type ImageSliderProps = {
  dotColor?: string
  imageHeight?: number
  images?: ImageProps[]
  style?: CSSProperties
  status?: string
} & Pick<CarouselProps, 'autoplay' | 'dotPosition' | 'dots' | 'easing' | 'effect'>

export type ImageProps = {
  imagePath?: string
  title?: string
  onClick?: () => void
}

const ImageSlider = (props: ImageSliderProps) => {
  const { dotColor, imageHeight, images, style, ...resProps } = props

  const content = useMemo(() => {
    const imageEmpty: ImageProps[] = [
      {
        imagePath: defaultNews,
        title: '',
      },
      {
        imagePath: defaultNews,
        title: ' ',
      },
    ]
    const isHaveImage = images?.length !== 0
    const imageContent = isHaveImage ? images : imageEmpty

    return imageContent?.map((image: ImageProps, index: number) => {
      const { imagePath = '', title, ...restProp } = image

      return (
        <SwiperSlide>
          <Image
            // src={imagePath}
            src={imagePath ? imagePath : `${config.apiHost}${imagePath}`}
            style={{
              zIndex: 'inherit',
              marginBottom: 16,
              objectFit: 'cover',
              borderRadius: 8
            }}
            width="100%"
            height={imageHeight || 250}
            title={title}
            alt={title}
            {...restProp}
          />
          <Text size={24} type="Bold">
            {title}
          </Text>
        </SwiperSlide>
      )
    })
  }, [images])

  return (
    <SlideLayout
      dotColor={dotColor}
      style={style}
      {...resProps}
    >
      <Swiper
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        pagination={true}
        modules={[Pagination]}
      >
        {content}
      </Swiper>
    </SlideLayout>
  )
}

export default ImageSlider
