import React, { useCallback, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { useScreen } from '../../utils/responsive-helper'
import Tabs from '../../components/common/Tabs'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import Tag from '../../components/common/Tag'
import coin from '../../assets/images/icons-coins.svg'
import color from '../../constant/colors'
import Button from '../../components/common/Button'
import { useGetNews, useGetNewsType } from '../../services/digital-news/digital-news-query'
import { useTranslation } from 'react-i18next'
import * as paths from '../../constant/path'
import { NewsType, NewsWithPaging } from '../../services/digital-news/digital-news-types'
import { useGetUser } from '../../services/user/user-query'
import Loading from '../../components/common/Loading'
import { Link } from 'react-router-dom'
import { useRouter } from '../../utils/helper'
import { useHistory } from 'react-router'
import { mobile } from '../../utils/responsive-helper'
import config from '../../config'
import dayjs from 'dayjs'

const CategoryContainer = styled.div`
  /* border: 1px solid #000000; */
`

const TabContainer = styled.div<{ isMobile: boolean }>`
  /* border: 1px solid #000000; */
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: ${(isMobile) => (isMobile ? 'center' : 'flex-start')};
  margin-bottom: 15px;
`

const NewsItemLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-self: stretch;
    justify-content: space-between;
    padding: 16px 0px;
  }

  .right {
    width: 176px;
    height: 100%;
    border-radius: 8px;
  }

  ${mobile} {
    flex-direction: column;
    row-gap: 16px;

    .left {    
      order: 2;
    }

    .right {
      width: 100%;
    }
  }
`
const NewsSubTitle = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;

  .date {
    display: flex;
    column-gap: 8px;
    align-items: center;
  }
`

const NewsCardStatus = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 18px;
  margin-bottom: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const NewsCardText = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
`

const MobileTag = styled(Tag)`
  width: 75px;
  height: 17px;
  padding: 2px 8px 0;
  border-radius: 3px;
`

const CustomDiv = styled.div`
  /* border: 1px solid #000000; */
  display: flex;
  flex-direction: row;
`

const CoinImage = styled(Image)`
  object-fit: contain;
  width: 16px;
  height: 16px;
  margin-left: 8px;
`

const GreenCircle = styled.div`
  background-color: ${color.SECONDARY};
  height: 18px;
  width: 18px;
  border-radius: 9px;
`

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 41px;
  width: 100%;
`
const CategoryTabsContainer = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 32px;
`
const CategoryTabsItem = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => isActive ? color.PRIMARY : color.WHITE};
  color: ${({ isActive }) => isActive ? color.WHITE : color.PRIMARY};
  padding: 2px 10px;
  border-radius: 16px;
  border: ${({ isActive }) => isActive ? null : `1px solid ${color.PRIMARY};`};
  cursor: pointer;
`

type CategoryPageProps = {
  name: string
}

type NewsCardProps = {
  news: NewsType
}

const NewsCard = (props: NewsCardProps) => {
  const { news } = props
  const { digitalNews, isRead } = news
  const { t, i18n } = useTranslation()

  const newsPath = useMemo(() => {
    return paths.newsDetails({ routeParam: { newsId: news.digitalNews.id } })
  }, [news])

  return (
    <Link to={newsPath}>
      <NewsItemLayout>
        <div className='left'>
          <Text size={16} type="Bold">{digitalNews.title}</Text>
          <NewsSubTitle>
            <div className='date'>
              <Image imageName='calendarIcon' />
              <Text size={10}>{dayjs(digitalNews.digitalNewsPublishDate).locale(i18n.language).format('DD MMM YYYY')}</Text>
            </div>
            <Button background={color.BACKGROUND} style={{
              borderRadius: 16,
              width: 'fit-content',
              padding: '2px 10px',
              height: 'fit-content',
            }}>
              <Text size={14}>{digitalNews.digitalNewsType}</Text>
            </Button>
          </NewsSubTitle>
        </div>
        <Image
          className='right'
          src={digitalNews?.thumbnailPath?.indexOf('http') > -1 ? digitalNews.thumbnailPath : `${config.apiHost}${digitalNews.thumbnailPath}`}
          style={{ objectFit: 'cover', width: 267, height: 189 }}
        />
      </NewsItemLayout>
    </Link>
  )
}

const CategoryPage = (props: CategoryPageProps) => {
  const { query } = useRouter<{ type?: string }>()
  const { name } = props
  const { data: user } = useGetUser()
  const { userBudget } = user || {}
  const { userId } = userBudget || {}
  const { type = 'Digital News' } = query || {}
  const { data: categoryTabs } = useGetNewsType()
  const [categoryName, setCategoryName] = React.useState("All")
  const { data, fetchNextPage, refetch, hasNextPage, isLoading, isFetching } = useGetNews({
    size: 12,
    userId: userId,
    category: categoryName,
    subCategory: type,
  })
  const { isMobile } = useScreen()

  useEffect(() => {
    refetch()
  }, [type, categoryName])

  const onFetchMoreNews = useCallback(() => {
    fetchNextPage()
  }, [fetchNextPage])


  return (
    <Loading style={{ height: 100 }} loading={isLoading}>
      <CategoryTabsContainer>
        {categoryTabs && (
          categoryTabs.map((category, idx) => (
            <CategoryTabsItem
              key={idx}
              isActive={categoryName === category.name}
              onClick={() => setCategoryName(category.name)}
            >
              {category.name}
            </CategoryTabsItem>
          ))
        )}
      </CategoryTabsContainer>
      <TabContainer isMobile={isMobile}>
        {data &&
          data.pages.map(({ data: page }: NewsWithPaging) =>
            page.filter((newFilter: NewsType) => newFilter.digitalNews.subType === name)
              .map((news: NewsType) => <NewsCard key={news.digitalNews.id} news={news} />),
          )}
      </TabContainer>
      {/* <LoadMoreContainer>
        <Loading loading={!isLoading && isFetching}>
          <Button onClick={onFetchMoreNews} disabled={!hasNextPage}>
            {t('news.more')}
          </Button>
        </Loading>
      </LoadMoreContainer> */}
    </Loading>
  )
}

const Category = () => {
  const { query } = useRouter<{ type?: string }>()
  const { type = 'Digital News' } = query || {}
  const { isDesktop } = useScreen()
  const history = useHistory()
  const { t } = useTranslation()

  //Mock Subtype
  const categorySubType = useMemo(() => {
    return [{ id: 1, title: 'Digital News', name: t('news.header.news'), logoPath: '', icon: '' }, { id: 2, title: 'Event', name: t('news.header.event'), logoPath: '', icon: '' }]
  }, [t])

  const dataSource = useMemo(() => {
    return categorySubType
      ? categorySubType.map((tab, idx) => ({
        title: tab.title,
        name: tab.name,
        key: idx,
        imageIcon: tab.logoPath,
        icon: tab.icon,
        page: <CategoryPage name={tab.title} />,
      }))
      : []
  }, [categorySubType])

  const convertNewsTypeToKey = useCallback(
    (type: string) => {
      return dataSource.find(({ title }) => title === type)?.key
    },
    [dataSource],
  )

  const convertNewsKeyToType = useCallback(
    (activeKey: string | number) => {
      return dataSource.find(({ key }) => key === activeKey)?.title
    },
    [dataSource],
  )

  const onTabsChange = useCallback(
    (key: string) => {
      const type = convertNewsKeyToType(key) || ''
      history.push(
        paths.news({
          queryParam: {
            type,
          },
        }),
      )
    },
    [convertNewsKeyToType, history],
  )

  const newsKey = useMemo(() => {
    return type && convertNewsTypeToKey(type)
  }, [type, convertNewsTypeToKey])


  return (
    <CategoryContainer>
      {categorySubType && dataSource.length && (
        <Tabs
          activeKey={newsKey}
          dataSource={dataSource}
          mode={isDesktop ? undefined : 'select'}
          onChange={onTabsChange}
        />

      )}
    </CategoryContainer>
  )
}

export default Category
