import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useGetUser } from '../../services/user/user-query'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'
import Text from '../../components/common/Text'
import Point from '../../components/common/Point'
import AdminButton from '../../components/common/AdminButton'

import { useHistory } from 'react-router'
import * as paths from '../../constant/path'
import { getToken } from '../../utils/helper'

const ProfileLayout = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: inherit;
  margin-bottom: 32px;

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    cursor: pointer;
  }
`

type ItemProps = {
  isShow?: boolean
}

const adminLink = 'https://fwd-main-be-uat.beliveasia.com/saml2/authenticate/okta?RelayState=ADMIN'

const Profile = (props: ItemProps) => {
  const { isShow } = props
  const history = useHistory()

  const { t, i18n } = useTranslation()
  const { data: user } = useGetUser()
  const { isMobile } = useScreen()
  return (
    <>
      <ProfileLayout>
        <div className='left'>
          <Text size={28} type="Bold">{t('play.title')} {i18n.language === 'en' ? `${user?.firstName} ${user?.lastName}` : `${user?.firstNameTH} ${user?.lastNameTH}`}</Text>
          {/* <Text size={16} type="Bold">{t('home.subtitle')}</Text> */}
        </div>
        <div className='right'>
          {
            user?.roles?.[0].roleName === "Administrator" && (
              <span onClick={() => window.open(`${adminLink}`, "_self")}>
                <AdminButton />
              </span>)
          }
          {
            isShow && (
              <span onClick={() => history.push(paths.eClaimHistory())}>
                <Point />
              </span>
            )
          }
        </div>
      </ProfileLayout>

    </>
  )
}

export default Profile