import styled from '@emotion/styled'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import Button from '../../components/common/Button'
import Card from '../../components/common/Card'
import {
  DatePickerField,
  InputField,
  SelectField,
  UploadfileField,
} from '../../components/common/fields'
import OutlineButton from '../../components/common/OutlineButton'
import Text from '../../components/common/Text'
import FooterContent from '../../components/FooterContent'
import { numberWithCommas } from '../../utils/helper'
import { mobile, mobileVerySmall } from '../../utils/responsive-helper'

import { Checkbox, Input } from 'antd'
import dayjs from 'dayjs'
import { Moment } from 'moment'
import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { IconError } from '../../assets/images/images-scg'
import Image from '../../components/common/Image'
import { InputLabel } from '../../components/common/Input'
import Modal from '../../components/common/Modal'
import color from '../../constant/colors'
import * as paths from '../../constant/path'
import { EClaimInf, setClaimInfo } from '../../redux-store/e-claim-reducer'
import {
  useCheckClaimStatus,
  useCreateClaim,
  useCreateDisaster,
  useUploadFile
} from '../../services/e-claim/e-claim-query'
import { EClaimFormParams, EClaimItem } from '../../services/e-claim/e-claim-types'
import { useGetUser, useGetUserCoin } from '../../services/user/user-query'
import { useVisibility } from '../../utils/custom-hook'

const { TextArea } = Input;
const ClaimFormContainer = styled(Card)`
  margin-top: 32px;
  margin-bottom: 80px;
  margin-right: 16px;
  margin-left: 16px;
  padding: 32px;
  .layout-upload {
    ${mobile} {
      width: 100%;
    }
    ${mobileVerySmall} {
      width: 100%;
    }
  }
  ${mobile} {
    padding: 16px;
  }
  ${mobileVerySmall} {
    padding: 16px;
  }
`

const TitleContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Spacer = styled.div`
  width: 20px;
  height: 20px;

  ${mobile} {
    display: none;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const InputSubContainer = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;

  ${mobile} {
    flex-direction: column;
  }
`

const ErrorIcon = styled(Image)`
object-fit: contain;
width: 72px;
height: 72px;
`

const CustomEditableFooter = styled.div`
  height: 80;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  ${mobile} {
    justify-content: center;
  }
`

const BackButton = styled(Button)`
  width: 267px;
  position: absolute;
  margin-top:18px
`

const CustomUnEditableFooter = styled(FooterContent)`
  height: 80;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  ${mobile} {
    flex-direction: column;
    justify-content: center;
  }
`

const SumPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  ${mobile} {
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const SubmitButton = styled(Button)`
  width: 267px;
`
const TextModal = styled(Text)`
  text-align: center;
`

const CheckBoxStyle = styled(Checkbox)`
  font-size: 18px;
  color: #E87722;
  font-family: NotoSansThai-Bold;
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 30%;
  /* border: 1px solid; */
  margin-bottom: 8px;
  padding-bottom: 0;

  ${mobile} {
    width: 100%;
  }
`

export const ECLAIM_FORM_NAME = 'ECLAIM_FORM'
type EClaimParam = {
  eClaimItem?: EClaimItem
  editable?: boolean
  eClaimFormValue?: EClaimInf['claimInfo']
  isPreview?: boolean
  isHistory?: boolean
  claimItem?: any
}
const required = (value: string) => (value ? undefined : 'error.required')
const requiredDate = (value: Moment) => {
  const x = moment(value).isValid() ? undefined : 'error.required'
  return x
}
const EClaimForm: ComponentType<
  EClaimParam & InjectedFormProps<EClaimFormParams, EClaimParam, string>
> = (props) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    invalid,
    eClaimItem,
    claimItem,
    editable = true,
    eClaimFormValue,
    isPreview = false,
    isHistory = false,
  } = props

  const formValuesSelector = getFormValues(ECLAIM_FORM_NAME)
  const formValues = useSelector<any, EClaimFormParams>(
    (state) => formValuesSelector(state) as EClaimFormParams,
  )

  const [visible, modalAction] = useVisibility()
  const [errorMessage, setErrorMessage] = useState('')
  const [isAccept, setIsAccept] = useState(eClaimFormValue?.isAccept || false)
  const [isAccept2, setIsAccept2] = useState(eClaimFormValue?.isAccept2 || false)
  const [isAccept3, setIsAccept3] = useState(eClaimFormValue?.isAccept3 || false)
  const [isAccept4, setIsAccept4] = useState(eClaimFormValue?.isAccept4 || false)
  const [checkModal, setCheckModal] = useState(false)
  const [onCurrentCheck, setOnCurrentCheck] = useState(0)

  const [isConfirm] = useState(true)
  const [isConfirm2, setIsConfirm2] = useState(true)
  const [isConfirm3, setIsConfirm3] = useState(true)
  const [isConfirm4, setIsConfirm4] = useState(true)

  const [isCreate, setIsCreate] = useState(false)
  const [isCreate2, setIsCreate2] = useState(false)
  const [isCreate3, setIsCreate3] = useState(false)
  const [isCreate4, setIsCreate4] = useState(false)

  const [isObject, setIsObject] = useState({})
  const [isObject2, setIsObject2] = useState<any>({})
  const [isObject3, setIsObject3] = useState<any>({})
  const [isObject4, setIsObject4] = useState<any>({})
  const [isClickVerify, setIsClickVerify] = useState(false)
  const { t, i18n } = useTranslation()

  const [description, setDescription] = useState("")
  const { data: user } = useGetUser()
  const { firstName, lastName } = user || {}

  const fullname = `${firstName} ${lastName}`
  const history = useHistory()
  const { data: userCoin = 0 } = useGetUserCoin()
  // const { mutate: createUserClaim } = useCreateUserClaim()
  const { mutate: createUserClaim, isLoading: isSubmitting } = useCreateClaim()

  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFile()

  const { mutateAsync: createEvent, isLoading: isCreateingEvent } = useCreateDisaster()

  const { data: validationData, refetch } = useCheckClaimStatus({
    budget_amount: formValues?.amount || 0,
    sub_claim_id: formValues?.subClaimId && formValues?.subClaimId + 5 || 0,
    claimId: 24
  })

  const { data: validationData3, refetch: refresh1 } = useCheckClaimStatus({
    budget_amount: formValues?.amount_3 || 0,
    sub_claim_id: formValues?.subClaimId && formValues?.subClaimId + 10 || 0,
    claimId: 25
  })

  const { data: validationData4, refetch: refresh2 } = useCheckClaimStatus({
    budget_amount: formValues?.amount_4 || 0,
    sub_claim_id: formValues?.subClaimId && formValues?.subClaimId + 15 || 0,
    claimId: 26
  })


  const titleOptions = useMemo(() => [
    {
      label: t("eClaim.typeOfDamage.floods"),
      value: 4,
    },
    {
      label: t("eClaim.typeOfDamage.earthquakes"),
      value: 5,
    },
    {
      label: t("eClaim.typeOfDamage.fire"),
      value: 6,
    },
    {
      label: t("eClaim.typeOfDamage.landslides"),
      value: 7,
    },
    {
      label: t("eClaim.typeOfDamage.windstorms"),
      value: 8,
    },
  ], [t])

  const convertBase64ToFile = useCallback(async (imageBase64: string) => {
    const res = await fetch(imageBase64)
    const blobFile = await res.blob()
    const file = new File([blobFile], `${Date.now()}.${blobFile.type.split('/')[1].split(';')[0]}`, { type: 'image/png' })
    return file
  }, [])


  const onClickBackToEnrollment = useCallback(() => {
    // history.push(main())
    history.goBack()
  }, [history])

  const onSubmit = useCallback(
    (form: EClaimFormParams) => {
      dispatch(
        setClaimInfo({
          id: claimItem?.id,
          name: form.name,
          paymentDate: moment(form.paymentDate),
          amount: form.amount || 0,
          paymentDate_2: moment(form.paymentDate_2),
          amount_2: form.amount_2 || 0,
          paymentDate_3: moment(form.paymentDate_3),
          amount_3: form.amount_3 || 0,
          paymentDate_4: moment(form.paymentDate_4),
          amount_4: form.amount_4 || 0,
          files: form.files,
          remark: form.remark,
          relativeId: form.relativeId,
          allpayRef: form.allpayRef,
          description: description,
          isAccept,
          isAccept2,
          isAccept3,
          isAccept4,
          subClaimId: form.subClaimId
        }),
      )
      if (editable === true) {
        history.push(paths.eClaimSummaryDisaster())
      }
    },
    [dispatch,
      eClaimItem?.claimId,
      editable,
      history,
      claimItem?.id,
      isAccept,
      isAccept2,
      isAccept3,
      isAccept4],
  )

  const getImageUploadUrl = useCallback(async () => {
    if (eClaimFormValue?.files) {
      var name = Object.keys(eClaimFormValue?.files);
      // let newFile = []

      let newFile = await name.map(async (path) => {
        const files = await convertBase64ToFile(eClaimFormValue?.files[path] || '')
        return uploadFile({ files, path })
      })

      return await Promise.all(newFile)
    }
  }, [
    convertBase64ToFile,
    uploadFile,
  ])

  const getEventId = useCallback(async () => {
    let name = eClaimFormValue?.description || ''
    let type = eClaimFormValue?.subClaimId ? eClaimFormValue?.subClaimId - 3 : 0
    let newFile = await createEvent({ name, type })

    return await newFile
  }, [
    createEvent,
  ])

  useEffect(() => {
    if (isAccept && isAccept2 && isAccept3 && isAccept4 && isCreate4) {
      history.push(
        paths.eClaimCompletedDisaster({
          routeParam: { eClaimId: eClaimItem?.claimId || 0 },
          queryParam: {
            eClaimName: 'เงินช่วยเหลือจำเป็นเร่งด่วน',
            eClaimNumber: `${moment().format(`YYYY-MM-${String(isObject4.id - 3).padStart(4, '0')}`)}`,
            eClaimPrice: 3000,
            eClaimName_2: 'ค่าที่พักอาศัยชั่วคราว',
            eClaimNumber_2: `${moment().format(`YYYY-MM-${String(isObject4.id - 2).padStart(4, '0')}`)}`,
            eClaimPrice_2: eClaimFormValue?.amount || 0,
            eClaimName_3: 'ค่าเดินทางตามความจำเป็นเร่งด่วน',
            eClaimNumber_3: `${moment().format(`YYYY-MM-${String(isObject4.id - 1).padStart(4, '0')}`)}`,
            eClaimPrice_3: eClaimFormValue?.amount_3 || 0,
            ...isObject4,
          },
        }),
      )
    } else if (isAccept && isAccept2 && isAccept3 && isCreate3) {
      history.push(
        paths.eClaimCompletedDisaster({
          routeParam: { eClaimId: eClaimItem?.claimId || 0 },
          queryParam: {
            eClaimName: 'เงินช่วยเหลือจำเป็นเร่งด่วน',
            eClaimNumber: `${moment().format(`YYYY-MM-${String(isObject3.id - 2).padStart(4, '0')}`)}`,
            eClaimPrice: 3000,
            eClaimName_2: 'ค่าที่พักอาศัยชั่วคราว',
            eClaimNumber_2: `${moment().format(`YYYY-MM-${String(isObject3.id - 1).padStart(4, '0')}`)}`,
            eClaimPrice_2: eClaimFormValue?.amount || 0,
            ...isObject3,
          },
        }),
      )
    } else if (isAccept && isAccept3 && isAccept4 && isCreate4) {
      history.push(
        paths.eClaimCompletedDisaster({
          routeParam: { eClaimId: eClaimItem?.claimId || 0 },
          queryParam: {
            eClaimName: 'เงินช่วยเหลือจำเป็นเร่งด่วน',
            eClaimNumber: `${moment().format(`YYYY-MM-${String(isObject4.id - 2).padStart(4, '0')}`)}`,
            eClaimPrice: 3000,
            eClaimName_3: 'ค่าเดินทางตามความจำเป็นเร่งด่วน',
            eClaimNumber_3: `${moment().format(`YYYY-MM-${String(isObject4.id - 1).padStart(4, '0')}`)}`,
            eClaimPrice_3: eClaimFormValue?.amount_3 || 0,
            ...isObject4,
          },
        }),
      )
    } else if (isAccept && isAccept2 && isCreate2) {
      history.push(
        paths.eClaimCompletedDisaster({
          routeParam: { eClaimId: eClaimItem?.claimId || 0 },
          queryParam: {
            eClaimName: 'เงินช่วยเหลือจำเป็นเร่งด่วน',
            eClaimNumber: `${moment().format(`YYYY-MM-${String(isObject2.id - 1).padStart(4, '0')}`)}`,
            eClaimPrice: 3000,
            ...isObject2,
          },
        }),
      )
    } else if (isAccept && isAccept3 && isCreate3) {
      history.push(
        paths.eClaimCompletedDisaster({
          routeParam: { eClaimId: eClaimItem?.claimId || 0 },
          queryParam: {
            eClaimName: 'เงินช่วยเหลือจำเป็นเร่งด่วน',
            eClaimNumber: `${moment().format(`YYYY-MM-${String(isObject3.id - 1).padStart(4, '0')}`)}`,
            eClaimPrice: 3000,
            ...isObject3,
          },
        }),
      )
    } else if (isAccept && isAccept4 && isCreate4) {
      history.push(
        paths.eClaimCompletedDisaster({
          routeParam: { eClaimId: eClaimItem?.claimId || 0 },
          queryParam: {
            eClaimName: 'เงินช่วยเหลือจำเป็นเร่งด่วน',
            eClaimNumber: `${moment().format(`YYYY-MM-${String(isObject4.id - 1).padStart(4, '0')}`)}`,
            eClaimPrice: 3000,
            ...isObject4,
          },
        }),
      )
    } else if (isAccept2 && isAccept3 && isAccept4 && isCreate4) {
      history.push(
        paths.eClaimCompletedDisaster({
          routeParam: { eClaimId: eClaimItem?.claimId || 0 },
          queryParam: {
            eClaimName_2: 'ค่าที่พักอาศัยชั่วคราว',
            eClaimNumber_2: `${moment().format(`YYYY-MM-${String(isObject4.id - 2).padStart(4, '0')}`)}`,
            eClaimPrice_2: eClaimFormValue?.amount || 0,
            eClaimName_3: 'ค่าเดินทางตามความจำเป็นเร่งด่วน',
            eClaimNumber_3: `${moment().format(`YYYY-MM-${String(isObject4.id - 1).padStart(4, '0')}`)}`,
            eClaimPrice_3: eClaimFormValue?.amount_3 || 0,
            ...isObject4,
          },
        }),
      )
    } else if (isAccept2 && isAccept4 && isCreate4) {
      history.push(
        paths.eClaimCompletedDisaster({
          routeParam: { eClaimId: eClaimItem?.claimId || 0 },
          queryParam: {
            eClaimName_2: 'ค่าที่พักอาศัยชั่วคราว',
            eClaimNumber_2: `${moment().format(`YYYY-MM-${String(isObject4.id - 1).padStart(4, '0')}`)}`,
            eClaimPrice_2: eClaimFormValue?.amount || 0,
            ...isObject4,
          },
        }),
      )
    } else if (isAccept3 && isAccept4 && isCreate4) {
      history.push(
        paths.eClaimCompletedDisaster({
          routeParam: { eClaimId: eClaimItem?.claimId || 0 },
          queryParam: {
            eClaimName_3: 'ค่าเดินทางตามความจำเป็นเร่งด่วน',
            eClaimNumber_3: `${moment().format(`YYYY-MM-${String(isObject4.id - 1).padStart(4, '0')}`)}`,
            eClaimPrice_3: eClaimFormValue?.amount_3 || 0,
            ...isObject4,
          },
        }),
      )
    }
  }, [isCreate, isCreate2, isCreate3, isCreate4, isObject, isObject2, isObject3, isObject4])

  const onSubmitApplication = useCallback(async () => {
    //uploadfile
    try {
      let files = await getImageUploadUrl()
      let eventId = await getEventId()

      if (isAccept) {
        createUserClaim(
          {
            userId: user?.userId,
            claimId: 23,
            claimDate: eClaimFormValue?.paymentDate!,
            subClaimId: eClaimFormValue?.subClaimId,
            claimDescription: eClaimFormValue?.description,
            eventDisasterId: eventId?.id,
            claimAmount: 3000,
            relativeId: eClaimFormValue?.relativeId === '0' ? undefined : eClaimFormValue?.relativeId,
            files: {
              insert: files?.map((data: any) => { return data[0] })
            }
          },
          {
            onSuccess: (data: any) => {
              if (!isAccept2 && !isAccept3 && !isAccept4) {
                history.push(
                  paths.eClaimCompletedDisaster({
                    routeParam: { eClaimId: eClaimItem?.claimId || 0 },
                    queryParam: {
                      eClaimName: data?.claimName || '',
                      eClaimNumber: data?.claimNumber || '',
                      eClaimPrice: 3000,
                    },
                  }),
                )
              }
            },
            onError: (error) => {
              setErrorMessage(error.message)
            },
          },
        )
      }

      if (isAccept2) {

        createUserClaim(
          {
            userId: user?.userId,
            claimId: 24,
            claimDate: eClaimFormValue?.paymentDate!,
            subClaimId: eClaimFormValue?.subClaimId && eClaimFormValue?.subClaimId + 5,
            claimAmount: eClaimFormValue?.amount || 0,
            eventDisasterId: eventId?.id,
            claimDescription: eClaimFormValue?.description,
            relativeId: eClaimFormValue?.relativeId === '0' ? undefined : eClaimFormValue?.relativeId,
            files: {
              insert: files?.map((data: any) => { return data[0] })
            }
          },
          {
            onSuccess: (data: any) => {
              if (!isAccept && !isAccept3 && !isAccept4) {
                history.push(
                  paths.eClaimCompletedDisaster({
                    routeParam: { eClaimId: eClaimItem?.claimId || 0 },
                    queryParam: {
                      eClaimName_2: data?.claimName || '',
                      eClaimNumber_2: data?.claimNumber || '',
                      eClaimPrice_2: eClaimFormValue?.amount || 0,
                    },
                  }),
                )
              }
              else {
                setIsObject2({
                  id: data.id,
                  eClaimName_2: 'ค่าที่พักอาศัยชั่วคราว',
                  eClaimNumber_2: data?.claimNumber || '',
                  eClaimPrice_2: eClaimFormValue?.amount || 0,
                })

                setIsCreate2(true)
              }
            },
            onError: (error) => {
              setErrorMessage(error.message)
            },
          },
        )
      }

      if (isAccept3) {
        createUserClaim(
          {
            userId: user?.userId,
            claimId: 25,
            claimDate: eClaimFormValue?.paymentDate!,
            subClaimId: eClaimFormValue?.subClaimId && eClaimFormValue?.subClaimId + 10,
            claimAmount: eClaimFormValue?.amount_3 || 0,
            eventDisasterId: eventId?.id,
            claimDescription: eClaimFormValue?.description,
            relativeId: eClaimFormValue?.relativeId === '0' ? undefined : eClaimFormValue?.relativeId,
            files: {
              insert: files?.map((data: any) => { return data[0] })
            }
          },
          {
            onSuccess: (data: any) => {
              if (!isAccept2 && !isAccept && !isAccept4) {
                history.push(
                  paths.eClaimCompletedDisaster({
                    routeParam: { eClaimId: eClaimItem?.claimId || 0 },
                    queryParam: {
                      eClaimName_3: data?.claimName || '',
                      eClaimNumber_3: data?.claimNumber || '',
                      eClaimPrice_3: eClaimFormValue?.amount_3 || 0,
                    },
                  }),
                )
              }
              else {
                setIsObject3({
                  id: data.id,
                  eClaimName_3: 'ค่าเดินทางตามความจำเป็นเร่งด่วน',
                  eClaimNumber_3: data?.claimNumber || '',
                  eClaimPrice_3: eClaimFormValue?.amount_3 || 0,
                })
                setIsCreate3(true)
              }
            },
            onError: (error) => {
              setErrorMessage(error.message)
            },
          },
        )
      }

      if (isAccept4) {
        createUserClaim(
          {
            userId: user?.userId,
            claimId: 26,
            claimDate: eClaimFormValue?.paymentDate!,
            subClaimId: eClaimFormValue?.subClaimId && eClaimFormValue?.subClaimId + 15,
            claimAmount: eClaimFormValue?.amount_4 || 0,
            eventDisasterId: eventId?.id,
            claimDescription: eClaimFormValue?.description,
            relativeId: eClaimFormValue?.relativeId === '0' ? undefined : eClaimFormValue?.relativeId,
            files: {
              insert: files?.map((data: any) => { return data[0] })
            }
          },
          {
            onSuccess: (data: any) => {
              if (!isAccept2 && !isAccept3 && !isAccept) {
                history.push(
                  paths.eClaimCompletedDisaster({
                    routeParam: { eClaimId: eClaimItem?.claimId || 0 },
                    queryParam: {
                      eClaimName_4: data?.claimName || '',
                      eClaimNumber_4: data?.claimNumber || '',
                      eClaimPrice_4: eClaimFormValue?.amount_4 || 0,
                    },
                  }),
                )
              } else {
                setIsObject4({
                  id: data.id,
                  eClaimName_4: data?.claimName || '',
                  eClaimNumber_4: data?.claimNumber || '',
                  eClaimPrice_4: eClaimFormValue?.amount_4 || 0,
                })
                setIsCreate4(true)
              }
            },
            onError: (error) => {
              setErrorMessage(error.message)
            },
          },
        )
      }
    } catch (error) {
      console.log(error)
    }
  }, [
    getImageUploadUrl,
    createUserClaim,
    eClaimFormValue?.id,
    eClaimItem?.claimId,
    eClaimItem?.claimName,
    eClaimFormValue?.paymentDate,
    eClaimFormValue?.amount,
    eClaimFormValue?.remark,
    eClaimFormValue?.relativeId,
    eClaimFormValue?.allpayRef,
    fullname,
    history,
    modalAction,
  ])

  const isNotZero = useCallback(
    (value) => {
      if (value === '0') {
        return 'error.required'
      }
    }
    , [])

  const onSubmitCheckClaim = useCallback(
    (amount: any, id: any) => {
      setCheckModal(true)
      setIsClickVerify(true)

      if (id === null) {
        setOnCurrentCheck(2)
        setIsConfirm2(false)
        refetch()
      } else if (id === 3) {
        setOnCurrentCheck(3)
        setIsConfirm3(false)
        refresh1()
      } else {
        setOnCurrentCheck(4)
        setIsConfirm4(false)
        refresh2()
      }
    }, [validationData])

  useEffect(() => {
    if (validationData?.message && checkModal) {
      modalAction.show()
      setCheckModal(false)
      setIsClickVerify(false)
      setIsConfirm2(!!validationData?.status)
    }

  }, [validationData, isClickVerify])

  useEffect(() => {
    if (validationData3?.message && checkModal) {
      modalAction.show()
      setCheckModal(false)
      setIsClickVerify(false)
      setIsConfirm3(!!validationData3?.status)
    }


  }, [validationData3, isClickVerify])

  useEffect(() => {
    if (validationData4?.message && checkModal) {
      modalAction.show()
      setCheckModal(false)
      setIsConfirm4(!!validationData4?.status)
      setIsClickVerify(false)
    }

  }, [validationData4, isClickVerify])

  // useEffect(() => {
  //   if (validationData?.message) {
  //     modalAction.show()
  //   }
  // }, [validationData])

  const checkConfition = useMemo(() => {
    if (isConfirm && isConfirm2 && isConfirm3 && isConfirm4 && (isAccept || (isAccept2 && (formValues.paymentDate !== undefined)) || (isAccept3 && (formValues.paymentDate_3 !== undefined)) || (isAccept4 && (formValues.paymentDate_4 !== undefined)))) {
      return true

    } else {
      return false
    }
  }, [isConfirm, isConfirm2, isConfirm3, isConfirm4, isAccept, isAccept2, isAccept3, isAccept4])

  const checkNotUndefind = useMemo(() => {
    if (formValues?.files !== undefined) {
      let sentences = 0;
      Object.keys(formValues.files).forEach((el) => {
        if (formValues.files[el] === undefined) {
          return sentences += 1
        }
      })

      if (sentences !== 0) {
        return true
      }
    }
  }, [formValues])

  const checkAttachtment = useMemo(() => {
    if (claimItem?.claimDocument?.length > 0) {
      if (formValues?.files !== undefined) {
        if (Object.keys(formValues.files).length === claimItem?.claimDocument?.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }, [formValues, claimItem])


  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          modalAction.hide()
        }}
        hideCloseIcon
        onCancelText="ตกลง"
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          rowGap: 24,
          paddingTop: 24
        }}>
          <ErrorIcon src={IconError} />

          <TextModal size={24} type="Bold">
            {onCurrentCheck === 2 && (
              i18n.language === 'en' ? validationData?.messageEn : validationData?.message
            )}

            {onCurrentCheck === 3 && (
              i18n.language === 'en' ? validationData3?.messageEn : validationData3?.message
            )}

            {onCurrentCheck === 4 && (
              i18n.language === 'en' ? validationData4?.messageEn : validationData4?.message
            )}
          </TextModal>
          <TextModal>
            {onCurrentCheck === 2 && (
              <>
                {!validationData?.status ? (i18n.language === 'en' ? validationData?.descriptionEn : validationData?.description) : `${t('disaster.content')}`}
              </>
            )}

            {onCurrentCheck === 3 && (
              <>
                {!validationData3?.status ? (i18n.language === 'en' ? validationData3?.descriptionEn : validationData3?.description) : `${t('disaster.content2')}`}
              </>
            )}

            {onCurrentCheck === 4 && (
              <>
                {!validationData4?.status ? (i18n.language === 'en' ? validationData4?.descriptionEn : validationData4?.description) : `${t('disaster.content3')}`}
              </>
            )}


          </TextModal>
        </div>
      </Modal>

      {/* <TitleContainer>
        <Text type="Bold" size={28}>
          สวัสดิการที่แลกเพิ่มได้
        </Text>
      </TitleContainer> */}
      <ClaimFormContainer>
        <div style={{ backgroundColor: color.WHITE, borderRadius: '5px', padding: 8 }}>
          <Text size={24} type="Bold" style={{ paddingBottom: 32 }}>
            {editable ? `${t('eClaim.info.inputDetails')}` : `${t('eClaim.info.checkDetails')}`}
          </Text>
          <InputContainer>
            <FlexCol>
              {editable && (
                <InputField
                  name="fullname"
                  label={t('eClaim.field.fullName')}
                  placeholder={fullname}
                  disabled
                />
              )}

              {!editable && (
                <>  <InputLabel>{t('eClaim.field.fullName')}</InputLabel>
                  <Text type="Bold">{fullname}</Text></>
              )
              }

            </FlexCol>

          </InputContainer>
          <Text size={20}>
            {t('eClaim.field.typeOfDamage')}
          </Text>
          <InputContainer>

            <FlexCol>
              {editable && (
                <SelectField
                  name="subClaimId"
                  validate={[requiredDate]}
                  placeholder={t('eClaim.field.typeOfDamage')}
                  options={titleOptions}
                />
              )}

              {!editable && (
                <Text type="Bold">{titleOptions?.find((v: any) => v?.value === eClaimFormValue?.subClaimId)?.label}</Text>
              )}
            </FlexCol>

          </InputContainer>
          <Text size={20}>
            {t('eClaim.field.summarizeDamage')}
          </Text>
          <InputContainer>
            <FlexCol>
              {
                editable && (
                  <TextArea style={{ marginBottom: 16 }} rows={4} value={description} onChange={event => setDescription(event.target.value)} />
                )
              }

              {!editable && (
                <Text type="Bold">- {eClaimFormValue?.description}</Text>
              )
              }
            </FlexCol>
          </InputContainer>

          {
            ((formValues?.subClaimId && description) || !editable) && (
              <>
                <Text size={20}>
                  {t('app.selectHelp')}
                </Text>
                <InputContainer>
                  <FlexCol>
                    <CheckBoxStyle
                      className="check-box"
                      disabled={!editable}
                      checked={isAccept}
                      onChange={(v) => {
                        setIsAccept((prev) => !prev)
                      }}
                    >
                      {t("eClaim.claimTopics.first")}
                    </CheckBoxStyle>
                  </FlexCol>
                </InputContainer>
                <InputContainer>
                  <FlexCol>
                    <CheckBoxStyle
                      className="check-box"
                      disabled={!editable}
                      checked={isAccept2}
                      onChange={(v) => {
                        setIsAccept2((prev) => {
                          if (prev === false) {
                            setIsConfirm2(false)
                          }
                          return !prev
                        })
                      }}
                    >
                      {t("eClaim.claimTopics.second")}
                    </CheckBoxStyle>
                  </FlexCol>
                </InputContainer>

                {
                  isAccept2 && (
                    <>
                      <InputSubContainer>
                        <FlexCol>
                          {editable && (
                            <DatePickerField
                              name="paymentDate"
                              label={t('eClaim.field.claimDate')}
                              validate={[requiredDate]}
                              maxDate={dayjs().endOf('day')}
                              minDate={dayjs().startOf('year')}
                            />
                          )}
                          {!editable && <InputLabel>{t('eClaim.field.claimDate')}</InputLabel>}
                          {!editable && (
                            <Text type="Bold">{eClaimFormValue?.paymentDate?.format('DD/MM/YYYY')}</Text>
                          )}
                        </FlexCol>
                        {/* <Spacer /> */}

                      </InputSubContainer>
                      <InputSubContainer>

                        <FlexCol>
                          {editable && (
                            <InputField
                              name="amount"
                              label={t('eClaim.field.amountReceipt')}
                              placeholder={t('app.inputAmount')}
                              validate={[required, isNotZero]}
                            // inputType="number"
                            />
                          )}
                          {!editable && <InputLabel>{t('eClaim.field.amountReceipt')}</InputLabel>}
                          {!editable && (
                            <Text type="Bold">{numberWithCommas(eClaimFormValue?.amount || 0)}</Text>
                          )}
                        </FlexCol>
                        <Spacer />

                        <FlexCol>
                          {
                            editable && <OutlineButton onClick={() => onSubmitCheckClaim(eClaimFormValue?.amount, null)}>{t("eClaim.checkBalanceButton")}</OutlineButton>
                          }
                        </FlexCol>
                      </InputSubContainer>
                    </>
                  )
                }

                <InputContainer>
                  <FlexCol>
                    <CheckBoxStyle
                      className="check-box"
                      checked={isAccept3}
                      disabled={!editable}
                      onChange={(v) => {
                        setIsAccept3((prev) => {
                          if (prev === false) {
                            setIsConfirm3(false)
                          }
                          return !prev
                        })
                      }}
                    >
                      {t("eClaim.claimTopics.third")}
                    </CheckBoxStyle>
                  </FlexCol>
                </InputContainer>

                {
                  isAccept3 && (
                    <>
                      <InputSubContainer>

                        <FlexCol>
                          {editable && (
                            <DatePickerField
                              name="paymentDate_3"
                              label={t('eClaim.field.claimDate')}
                              validate={[requiredDate]}
                              maxDate={dayjs().endOf('day')}
                              minDate={dayjs().startOf('year')}
                            />
                          )}
                          {!editable && <InputLabel>{t('eClaim.field.claimDate')}</InputLabel>}
                          {!editable && (
                            <Text type="Bold">{eClaimFormValue?.paymentDate_3?.format('DD/MM/YYYY')}</Text>
                          )}
                        </FlexCol>
                        {/* <Spacer /> */}

                      </InputSubContainer>
                      <InputSubContainer>

                        <FlexCol>
                          {editable && (
                            <InputField
                              name="amount_3"
                              label={t('eClaim.field.amountReceipt')}
                              placeholder={t('app.inputAmount')}
                              validate={[required, isNotZero]}
                            // inputType="number"
                            />
                          )}
                          {!editable && <InputLabel>{t('eClaim.field.amountReceipt')}</InputLabel>}
                          {!editable && (
                            <Text type="Bold">{numberWithCommas(eClaimFormValue?.amount_3 || 0)}</Text>
                          )}
                        </FlexCol>
                        <Spacer />

                        <FlexCol>
                          {
                            editable && <OutlineButton onClick={() => onSubmitCheckClaim(eClaimFormValue?.amount_3, 3)}>{t("eClaim.checkBalanceButton")} </OutlineButton>
                          }

                        </FlexCol>
                      </InputSubContainer>
                    </>
                  )
                }

                <InputContainer>
                  <FlexCol>
                    <CheckBoxStyle
                      className="check-box"
                      checked={isAccept4}
                      disabled={!editable}
                      onChange={(v) => {
                        setIsAccept4((prev) => {
                          if (prev === false) {
                            setIsConfirm4(false)
                          }
                          return !prev
                        })
                      }}
                    >
                      {t("eClaim.claimTopics.fourth")}
                    </CheckBoxStyle>
                  </FlexCol>
                </InputContainer>

                {
                  isAccept4 && (
                    <>
                      <InputSubContainer>
                        <FlexCol>
                          {editable && (
                            <DatePickerField
                              name="paymentDate_4"
                              label={t('eClaim.field.claimDate')}
                              maxDate={dayjs().endOf('day')}
                              minDate={dayjs().startOf('year')}
                              validate={[requiredDate]}
                            />
                          )}
                          {!editable && <InputLabel>{t('eClaim.field.claimDate')}</InputLabel>}
                          {!editable && (
                            <Text type="Bold">{eClaimFormValue?.paymentDate_4?.format('DD/MM/YYYY')}</Text>
                          )}
                        </FlexCol>
                        {/* <Spacer /> */}
                      </InputSubContainer>
                      <InputSubContainer>
                        <FlexCol>
                          {editable && (
                            <InputField
                              name="amount_4"
                              label={t('eClaim.field.amountReceipt')}
                              placeholder={t('app.inputAmount')}
                              validate={[required, isNotZero]}
                            // inputType="number"
                            />
                          )}
                          {!editable && <InputLabel>{t('eClaim.field.amountReceipt')}</InputLabel>}
                          {!editable && (
                            <Text type="Bold">{numberWithCommas(eClaimFormValue?.amount_4 || 0)}</Text>
                          )}
                        </FlexCol>
                        <Spacer />
                        <FlexCol>
                          {
                            editable && <OutlineButton onClick={() => onSubmitCheckClaim(eClaimFormValue?.amount_4, 4)}>{t("eClaim.checkBalanceButton")} </OutlineButton>
                          }
                        </FlexCol>
                      </InputSubContainer>
                    </>
                  )
                }

                {
                  (isAccept || (isAccept2 && (formValues?.paymentDate !== undefined)) || (isAccept3 && (formValues?.paymentDate_3 !== undefined)) || (isAccept4 && (formValues?.paymentDate_4 !== undefined))) && (
                    <>
                      <Text size={24} type="Bold">
                        {t('main.attached')}
                      </Text>

                      {
                        claimItem?.claimDocument?.map((data: any, index: any) =>
                          <FileUploadContainer>
                            <UploadfileField
                              name={`files[claimDocumentTypeId=${data.documentType.id}]`}
                              icon={'receiptIcon'}
                              title={i18n.language === 'en' ? data.documentType.nameEn : data.documentType.name}
                              desc={t('app.attachmentSize')}
                              isPreview={isPreview}
                              maximumFileSize={3}
                            />
                          </FileUploadContainer>

                        )
                      }
                    </>
                  )
                }


              </>
            )
          }

        </div>
      </ClaimFormContainer>
      {
        editable ? (
          <FooterContent>
            <BackButton onClick={onClickBackToEnrollment}>
              {t('eClaim.backButton')}
            </BackButton>
            <CustomEditableFooter>
              <SubmitButton onClick={handleSubmit(onSubmit)} disabled={invalid || !checkConfition || !checkAttachtment || (description === "") || checkNotUndefind}>
                {/* <SubmitButton onClick={handleSubmit(onSubmit)} disabled={invalid}> */}
                {t('eClaim.nextButton')}
              </SubmitButton>
            </CustomEditableFooter>
          </FooterContent>
        ) : (
          <FooterContent>
            <BackButton onClick={onClickBackToEnrollment}>
              {t('eClaim.backButton')}
            </BackButton>
            <CustomEditableFooter>
              <SubmitButton onClick={onSubmitApplication} disabled={isSubmitting || isUploading}>
                {t('eClaim.submitButton')}
              </SubmitButton>
            </CustomEditableFooter>
          </FooterContent>
        )
      }
    </>
  )
}

export default reduxForm<EClaimFormParams, EClaimParam, string>({
  form: ECLAIM_FORM_NAME,
})(EClaimForm)
