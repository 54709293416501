import { Redirect, Route, Switch, useLocation } from 'react-router'

import styled from '@emotion/styled'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as paths from '../constant/path'
import PageNotFound from '../pages/404'
import AuthCallback from '../pages/AuthCallback'
import Login from '../pages/Login'
import { ProtectedRoute } from './protected'

import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button from '../components/common/Button'
import Modal from '../components/common/Modal'
import Text from '../components/common/Text'
import Image from '../components/common/Image'
import { useHistory } from 'react-router'
import color from '../constant/colors'
import { useAcceptConsent, useGetUser } from '../services/user/user-query'
import mainImage from '../assets/images/base/mainModal.png'
import moment from 'moment'
import * as path from '../constant/path'

const FooterSubmitInsuranceModal = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  padding: 24px;
`

const BenefitSelectButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  min-width: 165px;
`

const ContentSubmitInsuranceModal = styled.div`
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`
const ContentModal = styled.div`
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
`

const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`

const BenefitSelectAButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  width: 100%;
  max-width: 100%;
`

export const Routes = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetUser()
  const history = useHistory()
  const { mutate } = useAcceptConsent()
  const [showPrivacy, setShowPrivacy] = useState(false)
  const location = useLocation();
  const [modal, setModal] = useState(moment().isBetween('2023-12-25', '2023-12-25'))
  const isAuthorized = useMemo(() => {
    return !!data
  }, [data])

  const onClickItem = useCallback(() => {
    mutate({
      onSuccess: (data: any) => {
      }
    }, {
      onSuccess: (data: any) => {
        setShowPrivacy(false)
      }
    })
  }, [])

  const onCancelClick = useCallback(() => {

  }, [])

  useEffect(() => {
    if ((location.pathname !== '/main') && data && !data?.isConsent) {
      setShowPrivacy(true)
    }
  }, [data, isLoading])

  return isLoading ? (
    <LoadingOutlined spin={isLoading} />
  ) : (
    <>
      <Modal
        visible={showPrivacy}
        hideCloseIcon
        onCancel={() => onCancelClick()}
        footerComponent={
          <FooterSubmitInsuranceModal>
            <BenefitSelectButton onClick={() => onClickItem()}>ยินยอม/Accept</BenefitSelectButton>
          </FooterSubmitInsuranceModal>
        }
      >
        <ContentSubmitInsuranceModal>
          <Text size={18} type="Bold" color='#E87722'>
            ความยินยอมเรื่องข้อมูลส่วนบุคคล (Consent for Use of Personal Data)
          </Text>
          <div>
            <Text>(1) ข้าพเจ้ารับทราบถึง<a style={{ textDecoration: 'underline', color: '#183028' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_TH_final.pdf' target='_blank'>นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>ของ บริษัท เอฟดับบลิวดี ประกันชีวิต จำกัด (มหาชน) (“FWD”) และยินยอมให้ FWD เก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของข้าพเจ้า เพื่อวัตถุประสงค์ในการดำเนินการตรวจสอบรายการเบิกค่าใช้จ่ายเกี่ยวกับผลประโยชน์และสวัสดิการที่ข้าพเจ้ามีสิทธิได้รับตามนโยบายของ FWD</Text>
            <Text color='#E87722'>I acknowledge the <a style={{ textDecoration: 'underline', color: '#E87722' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_EN_final.pdf' target='_blank'>privacy policy</a> of FWD Life Assurance Public Company Limited (“FWD”) and grant
              consent to FWD to collect, use my personal data and sensitive personal data for the purpose of conducting
              a reimbursement audit of the employee benefits to which I am entitled under FWD&#39;s policies.</Text>

            <Text>(2) ข้าพเจ้ารับรองและรับประกันว่า ข้าพเจ้าได้รับความยินยอมหรือสามารถอาศัยฐานทางกฎหมายอื่นสำหรับการเก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของสมาชิกครอบครัวข้าพเจ้าตามกฎหมายที่ใช้บังคับ</Text>
            <Text color='#E87722'>I certify and guarantee that I grant consent or can rely on other legal basis for the collection, use personal
              data and sensitive personal data of my family members in accordance with applicable law.</Text>
            <Text>(3) ในกรณีที่มีการเก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของสมาชิกครอบครัวข้าพเจ้า ข้าพเจ้ารับรองและรับประกันว่า ข้าพเจ้าได้แจ้ง<a style={{ textDecoration: 'underline', color: '#183028' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_TH_final.pdf' target='_blank'>นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>ของ FWD แก่สมาชิกครอบครัวข้าพเจ้าแล้ว ซึ่งมีการแจ้งวัตถุประสงค์ในการเก็บรวบรวม ใช้ข้อมูลส่วนบุคคลสำหรับพนักงาน</Text>
            <Text color='#E87722'>In case of collection, use personal data and sensitive personal data of my family members I certify and
              guarantee that I have informed FWD&#39;s <a style={{ textDecoration: 'underline', color: '#E87722' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_EN_final.pdf' target='_blank'>privacy policy</a> to my family members by informing the purpose of
              collection and use personal information for employees.</Text>

            <br />
            <Text>ข้าพเจ้ายินยอมและรับรองให้ FWD เก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลอ่อนไหวของข้าพเจ้ารวมถึงสมาชิกครอบครัวข้าพเจ้าเพื่อวัตถุประสงค์ข้างต้น</Text>
            <Text color='#E87722'>I grant consent and guarantee to FWD to collect and use my sensitive personal data including my family members
              for the above purposes.</Text>

          </div>
        </ContentSubmitInsuranceModal>
      </Modal>

      <Modal
        visible={modal}
        hideCloseIcon
        onCancelText={'a'}
        onCancel={() => setModal(true)}
        footerComponent={
          <FooterModal>
            <BenefitSelectAButton onClick={() => history.push(path.eClaimHistory())}>ดูประวัติการเบิก</BenefitSelectAButton>
          </FooterModal>
        }
      >
        <ContentModal>
          <Image src={mainImage} />
          <Text size={18} type="Bold">
            ไม่อยู่ในช่วงเวลาที่ให้บริการ
          </Text>
          <Text size={14} style={{
            textAlign: 'center'
          }}>
            ระบบจะเปิดให้ส่งเรื่องเบิกสวัสดิการอีกครั้ง <br />
            ในวันที่ 1 มกราคม 2567 ขออภัยในความไม่สะดวก
          </Text>

        </ContentModal>
      </Modal>

      <Switch>
        {!isAuthorized ? (
          <Route path={paths.authCallback()} component={AuthCallback} />
        ) : (
          <Redirect from={paths.authCallback()} to="/" />
        )}


        {!isAuthorized ? (
          <Route path={paths.login()} component={Login} />
        ) : (
          <Redirect from={paths.login()} to="/" />
        )}

        <Route path={paths.notFound()} component={PageNotFound} />

        {!isAuthorized ? <Redirect to={paths.login()} /> : <ProtectedRoute />}
      </Switch>
    </>
  )
}
