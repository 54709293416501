import React, { ComponentType, useMemo } from 'react'
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form'

import Text from '../Text'
import color from '../../../constant/colors'
import { useTranslation } from 'react-i18next'

const modifyComponent = (Component: ComponentType<any>) => (props: WrappedFieldProps) => {
  const { input, meta, ...restProps } = props
  const { error, touched, dirty } = meta

  const isError = useMemo(() => {
    return error && touched
  }, [error, touched])
  const { t } = useTranslation()
  return (
    <>
      <Component
        className={isError ? 'ant-form-item-has-error' : undefined}
        // isError={isError}
        meta={meta}
        {...input}
        {...restProps}
      />
      <Text style={{ marginTop: 8, height: 23 }} type="Medium" size={16} color={color.INPUT_ERROR}>
        {isError ? t(error) : ''}
      </Text>
    </>
  )
}
export const makeField = <T extends object>(component: ComponentType<any>) => {
  const newComponent = modifyComponent(component)
  const returnField = (props: T & { name: string } & BaseFieldProps) => (
    <Field {...props} component={newComponent} />
  )
  return returnField
}
