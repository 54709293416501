import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import { GET_USER_URL } from '../user/user-query'

import {
  CreateEclaimUser,
  EClaimEnllomentItemList,
  EClaimItem,
  EClaimList,
  GetEClaimByIdProps,
  UploadFile,
  CreateUserClaimEnrollments,
  GetRelativeResponse,
  EClaimListResponse,
  ECliamHistoryListResponse,
  GetEClaimHistoryByIdProps,
  RelativeScgResponse,
  ClaimTypeList,
  GetEClaimStatus,
  CheckClaimItem,
  CreateClaimUser,
  GetUserEClaimByIdProps,
  ClaimType,
  EClaimHistory,
  cancelUserClaim,
  DisasterEvent
} from './e-claim-types'

const APP = '/app'
export const USER_CLAIM = 'userClaim'
export const E_CLAIM_LIST = `${APP}/${USER_CLAIM}/getClaimList`
export const E_CLAIM_BY_ID = `${APP}/${USER_CLAIM}/getById`

export const E_CLAIM_LIST_SCG = `${APP}/${USER_CLAIM}/getAllUserClaimByUser`

export const E_CLAIM_HISTORY_BY_ID = `${APP}/${USER_CLAIM}/getUserClaimById`

export const CREATE_USER_CLAIM = `${APP}/${USER_CLAIM}/createUserClaim`
export const UPLOADFILE = `/fileUpload/uploadFile`
export const E_CLAIM_ENROLLMENT = `${APP}/${USER_CLAIM}/enrollment`
export const CREATE_USER_CLAIM_ENROLLMENT = `${APP}/${USER_CLAIM}/enrollment`
export const GET_RELATIVE = `${APP}/enrollments/relative`
export const GET_RELATIVE_SCG = `${APP}/enrollments/relative-list`
export const GET_GROUP_NUMBER = `${APP}/enrollments/self-benefits/group`


// fwd
export const GET_CLAIM_TYPE = '/api/claim/type'
export const GET_CLAIM_BY_ID = '/api/claim'
export const GET_ALL_CLAIM_TYPE = '/api/claim/type'
export const GET_ALL_USER_CLAIM = '/api/user/claim'
export const CHECK_CLAIM_STATUS = '/api/user/claim/check'
export const UPLOAD_CLAIM_FILE = '/api/user/claim/upload'
export const CREATE_CLAIM = '/api/user/claim/createUserClaim'
export const CANCEL_CLAIM = '/api/user/claim/cancelUserClaim'
export const RE_CREATE_CLAIM = '/api/user/claim/reCreateUserClaim'
export const CREATE_EVENT_DISASTER = '/api/user/claim/createEventDisaster'

export const useCreateClaim = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateClaimUser) => {
      const {
        userId,
        claimId,
        claimDate,
        subClaimId,
        claimAmount,
        claimDescription,
        relativeId,
        files,
        eventDisasterId,
        userRemark
      } = data
      return api.belive.post(
        CREATE_CLAIM,
        JSON.stringify({
          userId,
          claimId,
          claimDate,
          subClaimId,
          claimAmount,
          claimDescription,
          relativeId,
          files,
          eventDisasterId,
          userRemark
        }),
        { contentType: ContentType.JSON },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_URL)
        queryClient.invalidateQueries([GET_ALL_USER_CLAIM])
        // queryClient.invalidateQueries([E_CLAIM_LIST])
        // queryClient.invalidateQueries([E_CLAIM_BY_ID])
        // queryClient.invalidateQueries([E_CLAIM_LIST_SCG])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useCreateDisaster = () => {
  const queryClient = useQueryClient()

  const mutate = useMutation(
    async ({
      name,
      type
    }: {
      name: string,
      type: number
    }
    ) => {
      return api.belive.post<DisasterEvent>(
        `${CREATE_EVENT_DISASTER}`,
        { name, type },
        { contentType: ContentType.JSON },
      )
    },
    {
      onSuccess: (data) => {
        console.log('Create Event Success', data)
        queryClient.invalidateQueries([GET_ALL_USER_CLAIM])

      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}


export const useUploadFile = () => {
  const mutate = useMutation(
    async ({
      files,
      path
    }: {
      files: File,
      path: string
    }
    ) => {
      return api.belive.post<UploadFile>(
        `${UPLOAD_CLAIM_FILE}?${path}`,
        { files },
        { contentType: ContentType.FORMDATA },
      )
    },
    {
      onSuccess: (data) => {
        console.log('Upload File Success', data)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}

export const useCancelEClaim = () => {
  const queryClient = useQueryClient()

  const mutate = useMutation(
    async (data: any) => {
      return api.belive.post<cancelUserClaim>(
        `${CANCEL_CLAIM}/${data}`,
      )
    },
    {
      onSuccess: (data) => {
        console.log('Cancel Claims Success', data)
        queryClient.invalidateQueries(GET_ALL_USER_CLAIM)
        queryClient.invalidateQueries(GET_USER_URL)
        queryClient.invalidateQueries(`${GET_ALL_USER_CLAIM}/${data.id}`)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}

export const useUploadClaimFile = () => {
  const mutate = useMutation(
    async (file: File) => {
      return api.belive.post<UploadFile>(
        `${UPLOAD_CLAIM_FILE}?${file.name}`,
        { file },
        { contentType: ContentType.FORMDATA },
      )
    },
    {
      onSuccess: (data) => {
        console.log('Upload File Success', data)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}
export const useGetUserClaimById = (props: GetUserEClaimByIdProps) => {
  const { userClaimId } = props

  return useQuery([`${GET_ALL_USER_CLAIM}/${userClaimId}`], () => {
    return api.belive.get<EClaimHistory>(`${GET_ALL_USER_CLAIM}/${userClaimId}`)
  })
}

export const useGetClaimById = (props: GetEClaimByIdProps) => {
  const { claimId } = props

  return useQuery([`${GET_CLAIM_BY_ID}/${claimId}`], () => {
    return api.belive.get<EClaimItem>(`${GET_CLAIM_BY_ID}/${claimId}`)
  })
}

export const useCheckClaimStatus = (props: GetEClaimStatus) => {
  const { budget_amount, claimId, sub_claim_id, relative_id } = props

  const relativeId = relative_id !== "NaN" ? (relative_id === '0' ? null : relative_id) : null
  return useQuery([`${CHECK_CLAIM_STATUS}/${claimId}`, budget_amount],
    () => {
      return api.belive.get<CheckClaimItem>(`${CHECK_CLAIM_STATUS}/${claimId}`, { budget_amount: budget_amount, sub_claim_id: sub_claim_id, relative_id: relativeId })
    }, {
    enabled: false,
  })
}

export const useGetClaimType = () => {
  return useQuery([GET_CLAIM_TYPE], () => {
    return api.belive.get<ClaimTypeList>(`${GET_CLAIM_TYPE}`)
  })
}

export const useGetClaimTypeById = (props: GetEClaimByIdProps) => {
  const { claimId } = props

  return useQuery([`${GET_CLAIM_TYPE}/${claimId}`], () => {
    return api.belive.get<ClaimType>(`${GET_CLAIM_TYPE}/${claimId}`)
  })
}

export const useGetGroupNumber = () => {
  return useQuery(GET_GROUP_NUMBER, async () => {
    return await api.belive.get<any>(GET_GROUP_NUMBER)
  })
}

export const useGetEClaimList = () => {
  return useQuery([E_CLAIM_LIST], () => {
    return api.belive.get<EClaimList>(`${E_CLAIM_LIST}`)
  })
}

export const useGetEClaimListScg = () => {
  return useQuery([E_CLAIM_LIST], () => {
    return api.belive.get<EClaimListResponse>(`${E_CLAIM_LIST}`)
  })
}

export const useGetEClaimListHistory = () => {
  return useQuery([GET_ALL_USER_CLAIM], () => {
    return api.belive.get<ECliamHistoryListResponse>(`${GET_ALL_USER_CLAIM}`)
  })
}

export const useGetEClaimById = (props: GetEClaimByIdProps) => {
  const { claimId } = props

  return useQuery([E_CLAIM_BY_ID, claimId], () => {
    return api.belive.get<EClaimItem>(`${E_CLAIM_BY_ID}`, { id: claimId })
  })
}

export const useGetEClaimHistoryById = (props: GetEClaimHistoryByIdProps) => {
  const { id } = props

  return useQuery([E_CLAIM_HISTORY_BY_ID, id], () => {
    return api.belive.get<EClaimItem>(`${E_CLAIM_HISTORY_BY_ID}`, { id: id })
  })
}
export const useCreateUserClaim = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateEclaimUser) => {
      const {
        id,
        claimId,
        claimDate,
        claimName,
        exchangeCoin = 0,
        relativeTypeId,
        receiptFile,
        citizenFileUrl,
        remark = '',
        allpayRef = '',
      } = data
      return api.belive.post(
        CREATE_USER_CLAIM,
        JSON.stringify({
          id: id,
          claimId: claimId,
          exchangeCoin: exchangeCoin,
          claimName: claimName,
          claimDate: claimDate,
          receiptFile: receiptFile,
          citizenFileUrl: citizenFileUrl,
          userClaimPrice: exchangeCoin,
          relativeTypeId: relativeTypeId,
          remark: remark,
          allpayRef: allpayRef,
        }),
        { contentType: ContentType.JSON },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_LIST])
        queryClient.invalidateQueries([E_CLAIM_BY_ID])
        queryClient.invalidateQueries([E_CLAIM_LIST_SCG])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}
// export const useUploadFile = () => {
//   const mutate = useMutation(
//     async (file: File) => {
//       return api.belive.post<UploadFile>(
//         UPLOADFILE,
//         { file },
//         { contentType: ContentType.FORMDATA },
//       )
//     },
//     {
//       onSuccess: (data) => {
//         console.log('Upload File Success', data)
//       },
//       onError: (e: any) => {
//         if (e.message) {
//           console.log(e.message)
//         }
//       },
//     },
//   )
//   return mutate
// }

export const useGetEClaimEnrollment = (id: number | string) => {
  return useQuery([E_CLAIM_ENROLLMENT, id], () => {
    return api.belive.get<EClaimEnllomentItemList>(`${E_CLAIM_ENROLLMENT}`, { id: id })
  })
}

export const useCreateUserClaimEnrollment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateUserClaimEnrollments) => {
      return api.belive.post(CREATE_USER_CLAIM_ENROLLMENT, data, { contentType: ContentType.JSON })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_ENROLLMENT])
        queryClient.invalidateQueries([E_CLAIM_LIST])
        queryClient.invalidateQueries([E_CLAIM_BY_ID])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useGetRelative = () => {
  return useQuery([GET_RELATIVE], () => {
    return api.belive.get<GetRelativeResponse>(`${GET_RELATIVE}`)
  })
}

export const useGetRelativeOptions = () => {
  return useQuery([GET_RELATIVE, 'options'], async () => {
    const data = await api.belive.get<GetRelativeResponse>(`${GET_RELATIVE}`)
    const optionRelative: {
      label: string
      value: string | number
    }[] = []
    optionRelative.push({ label: 'ตัวเอง', value: '0' })

    data?.map((relativesGroup) => {
      return relativesGroup.relativeList.map((relative) => {
        const { firstname, lastname, relativeTypeId } = relative
        const label = `${firstname} ${lastname}`
        optionRelative.push({ label: `${firstname} ${lastname}`, value: relativeTypeId })
      })
    })
    return optionRelative
  })
}

export const useGetRelativeOptionScg = () => {
  return useQuery([GET_RELATIVE_SCG, 'options'], async () => {
    const data = await api.belive.get<RelativeScgResponse>(`${GET_RELATIVE_SCG}`)
    const optionRelative: {
      label: string
      value: string | number
    }[] = []
    optionRelative.push({ label: 'ตัวเอง', value: '0' })

    data.map((relative) => {
      const { firstname, lastname, id, typeName } = relative
      optionRelative.push({ label: `${typeName}: ${firstname} ${lastname}`, value: id })
    })

    return optionRelative
  })
}
