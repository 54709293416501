import styled from '@emotion/styled'
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import Card from '../../components/common/Card'
import {
  DatePickerField,
  InputField,
  SelectField,
  UploadfileField,
} from '../../components/common/fields'
import Text from '../../components/common/Text'
import { numberWithCommas } from '../../utils/helper'
import { mobile, mobileVerySmall } from '../../utils/responsive-helper'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Button from '../../components/common/Button'
import OutlineButton from '../../components/common/OutlineButton'
import FooterContent from '../../components/FooterContent'

import { Checkbox } from 'antd'
import dayjs from 'dayjs'
import { Moment } from 'moment'
import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { IconError } from '../../assets/images/images-scg'
import Image from '../../components/common/Image'
import { InputLabel } from '../../components/common/Input'
import Modal from '../../components/common/Modal'
import color from '../../constant/colors'
import * as paths from '../../constant/path'
import { EClaimInf, setClaimInfo } from '../../redux-store/e-claim-reducer'
import {
  useCheckClaimStatus,
  useCreateClaim,
  useUploadFile
} from '../../services/e-claim/e-claim-query'
import { EClaimFormParams, EClaimItem } from '../../services/e-claim/e-claim-types'
import { useGetUser } from '../../services/user/user-query'
import { useVisibility } from '../../utils/custom-hook'

const ClaimFormContainer = styled(Card)`
  margin-top: 32px;
  margin-bottom: 80px;
  margin-right: 16px;
  margin-left: 16px;
  padding: 32px;
  .layout-upload {
    ${mobile} {
      width: 100%;
    }
    ${mobileVerySmall} {
      width: 100%;
    }
  }
  ${mobile} {
    padding: 16px;
  }
  ${mobileVerySmall} {
    padding: 16px;
  }
`

const Spacer = styled.div`
  width: 20px;
  height: 20px;

  ${mobile} {
    display: none;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;

  ${mobile} {
    flex-direction: column;
  }
`

const CustomEditableFooter = styled.div`
  height: 80;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  ${mobile} {
    justify-content: center;
  }
`

const CustomUnEditableFooter = styled(FooterContent)`
  height: 80;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  ${mobile} {
    flex-direction: column;
    justify-content: center;
  }
`

const SumPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  ${mobile} {
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const SubmitButton = styled(Button)`
  width: 267px;
`

const CheckButton = styled(Button)`
  width: 318px;
`

const BackButton = styled(Button)`
  width: 267px;
  position: absolute;
  margin-top:18px
`

const TextModal = styled(Text)`
  text-align: center;
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 30%;
  /* border: 1px solid; */
  margin-bottom: 8px;
  padding-bottom: 0;

  ${mobile} {
    width: 100%;
  }
`

const ErrorIcon = styled(Image)`
object-fit: contain;
width: 72px;
height: 72px;
`

export const ECLAIM_FORM_NAME = 'ECLAIM_FORM'
type EClaimParam = {
  eClaimItem?: EClaimItem
  editable?: boolean
  eClaimFormValue?: EClaimInf['claimInfo']
  isPreview?: boolean
  allpayStatus?: boolean
  isHistory?: boolean
  claimItem?: any
  relativeId?: number
}
const required = (value: string) => (value ? undefined : 'error.required')
const requiredDate = (value: Moment) => {
  const x = moment(value).isValid() ? undefined : 'error.required'
  return x
}
const EClaimForm: ComponentType<
  EClaimParam & InjectedFormProps<EClaimFormParams, EClaimParam, string>
> = (props) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    invalid,
    eClaimItem,
    claimItem,
    editable = true,
    eClaimFormValue,
    isPreview = false,
    allpayStatus,
    isHistory = false,
    relativeId
  } = props

  const formValuesSelector = getFormValues(ECLAIM_FORM_NAME)
  const formValues = useSelector<any, EClaimFormParams>(
    (state) => formValuesSelector(state) as EClaimFormParams,
  )

  const [visible, modalAction] = useVisibility()
  const [errorMessage, setErrorMessage] = useState('')
  const [listSubClaims, setListSubClaims] = useState([{ label: "", value: "" }])
  const [checkModal, setCheckModal] = useState(false)
  const [isAccept, setIsAccept] = useState(false)
  const [isAccept2, setIsAccept2] = useState(false)
  const [isAccept3, setIsAccept3] = useState(false)
  const [isAccept4, setIsAccept4] = useState(false)
  const [isClickVerify, setIsClickVerify] = useState(false)
  const [isCheck, setIsCheck] = useState(true)
  const { data: user } = useGetUser()
  const { firstName, lastName } = user || {}
  const { t, i18n } = useTranslation()

  const userFullName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName])
  const relativeFullName = useMemo(() => {
    const { firstnameTh, lastnameTh, lastnameEn, firstnameEn } = user?.relatives?.find(item => item.id === relativeId) || {}
    return `${i18n.language === 'en' ? `${firstnameEn} ${lastnameEn}` : `${firstnameTh} ${lastnameTh}`}`
  }, [relativeId, user?.relatives])
  const getFullName = useMemo(() => {
    if (relativeId) {
      return relativeFullName
    }
    return userFullName
  }, [relativeFullName, relativeId, userFullName])

  const history = useHistory()
  const { mutate: createUserClaim, isLoading: isSubmitting } = useCreateClaim()

  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFile()

  const { data: validationData, refetch, isLoading: loading } = useCheckClaimStatus({
    budget_amount: formValues?.amount?.toString().replace(',', '') || 0,
    sub_claim_id: formValues?.subClaimId || 0,
    claimId: claimItem.id,
    relative_id: claimItem.claimType.id === 1 ? relativeId?.toString() || '' : ''
  })

  useEffect(() => {
    let valueSubClaims: any = [];

    if (claimItem?.subClaims?.length > 0) {

      if (i18n.language === 'en') {
        claimItem?.subClaims?.map((value: any) => {
          valueSubClaims.push({
            label: value.nameEn,
            value: value.id,
          });
        });
      } else {
        claimItem?.subClaims?.map((value: any) => {
          valueSubClaims.push({
            label: value.name,
            value: value.id,
          });
        });
      }

      setListSubClaims(valueSubClaims);
    }

  }, [claimItem])

  const convertBase64ToFile = useCallback(async (imageBase64: string) => {
    const res = await fetch(imageBase64)
    const blobFile = await res.blob()
    const file = new File([blobFile], `${Date.now()}.${blobFile.type.split('/')[1].split(';')[0]}`, { type: 'image/png' })
    return file
  }, [])

  const onSubmit = useCallback(
    (form: EClaimFormParams) => {
      dispatch(
        setClaimInfo({
          id: claimItem?.id,
          name: getFullName,
          paymentDate: moment(form.paymentDate),
          paymentDate_2: moment(),
          amount_2: 0,
          paymentDate_3: moment(),
          amount_3: 0,
          paymentDate_4: moment(),
          amount_4: 0,
          amount: form.amount || 0,
          files: form.files,
          remark: form.remark,
          relativeId: claimItem.claimType.id === 1 ? relativeId?.toString() || '' : '',
          allpayRef: form.allpayRef,
          subClaimId: form.subClaimId,
          userRemark: form.userRemark
        }),
      )
      if (editable === true) {
        history.push(paths.eClaimSummary({ routeParam: { eClaimId: claimItem?.id || 0 } }))
      }
    },
    [dispatch, eClaimItem?.claimId, editable, history, claimItem?.id],
  )

  const getImageUploadUrl = useCallback(async () => {
    if (eClaimFormValue?.files) {
      var name = Object.keys(eClaimFormValue?.files);
      let newFile: any[] = []

      newFile = name.map(async (path) => {
        if (eClaimFormValue?.files[path] && typeof eClaimFormValue?.files[path] === 'string') {
          const files = await convertBase64ToFile(eClaimFormValue?.files[path] || '')
          return uploadFile({ files, path })
        }
      })

      return await Promise.all(newFile)
    }
  }, [
    convertBase64ToFile,
    uploadFile,
  ])

  const onClickBackToEnrollment = useCallback(() => {
    // history.push(main())
    history.goBack()
  }, [history])

  const onClickGetBill = useCallback(() => {
    if (i18n.language === 'en') {
      window.open('https://app-main-1730.azurewebsites.net/files/Instruction_for_the_correct_receipt_EN.pdf', "_blank")
    }
    else {
      window.open('https://app-main-1730.azurewebsites.net/files/Instruction_for_the_correct_receipt_TH.pdf', "_blank")
    }
  }, [])

  const onSubmitApplication = useCallback(async () => {
    //uploadfile
    try {
      let files = await getImageUploadUrl()

      createUserClaim(
        {
          userId: user?.userId,
          claimId: claimItem?.id,
          claimDate: eClaimFormValue?.paymentDate!,
          subClaimId: eClaimFormValue?.subClaimId,
          claimAmount: eClaimFormValue?.amount!.toString().replace(',', ''),
          userRemark: eClaimFormValue?.userRemark,
          relativeId: eClaimFormValue?.relativeId || '',
          files: {
            insert: files?.map((data: any) => { return data[0] })
          }
        },
        {
          onSuccess: (data: any) => {
            history.push(
              paths.eClaimCompleted({
                routeParam: { eClaimId: eClaimItem?.claimId || 0 },
                queryParam: {
                  eClaimName: data?.claimName || '',
                  eClaimNameEn: data?.claimNameEn || '',
                  eClaimNumber: data?.claimNumber || '',
                  eClaimPrice: eClaimFormValue?.amount.toString().replace(',', '') || 0,
                },
              }),
            )
          },
          onError: (error) => {
            alert(error.message)
          },
        },
      )
    } catch (error) {
      console.log(error)
    }
  }, [
    getImageUploadUrl,
    createUserClaim,
    eClaimFormValue?.id,
    eClaimItem?.claimId,
    eClaimItem?.claimName,
    eClaimFormValue?.paymentDate,
    eClaimFormValue?.amount,
    eClaimFormValue?.remark,
    eClaimFormValue?.relativeId,
    eClaimFormValue?.allpayRef,
    getFullName,
    history,
    modalAction,
  ])

  const isNotZero = useCallback(
    (value) => {
      if (value === '0') {
        return 'error.required'
      }
    }
    , [])


  const checkNotUndefind = useMemo(() => {
    if (formValues?.files !== undefined) {
      let sentences = 0;
      Object.keys(formValues.files).forEach((el) => {
        if (formValues.files[el] !== undefined) {
          return sentences += 1
        }
      })

      if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(claimItem?.id)) {
        if (sentences === 0) {
          return true
        }
      } else {
        if (sentences !== claimItem?.claimDocument?.length) {
          return true
        }
      }

    }
  }, [formValues, claimItem])

  const checkAttachtment = useMemo(() => {
    if (claimItem?.claimDocument?.length > 0) {
      if (formValues?.files !== undefined) {
        if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(claimItem?.id)) {
          if (Object.keys(formValues.files).length > 0) {
            return true
          }
        } else if (Object.keys(formValues.files).length === claimItem?.claimDocument?.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }, [formValues, claimItem])

  const checkCurrentAmount = useMemo(() => {
    if (claimItem.claimType.id === 1 && user?.userBudget.budgetRemain === 0 && relativeId === 0) {
      return true
    } else if (claimItem.claimType.id === 1 && relativeId !== 0) {
      const { relativeBudget } = user?.relatives?.find(item => item.id === relativeId) || {}
      if (relativeBudget?.budgetRemain === 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [user])

  const checkAccept = useMemo(() => {
    if (claimItem?.id === 22) {
      setIsCheck(false)
      if ((isAccept || isAccept2) || isAccept3) {
        return false
      } else if (isAccept4) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }, [isAccept, isAccept2, isAccept3, isAccept4, claimItem])

  const checkIsOnce = useMemo(() => {
    if (claimItem?.id === 16 || claimItem?.id === 17) {
      if (validationData?.status) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }, [validationData, claimItem])

  const disbleField = allpayStatus ? true : false

  const onSubmitCheckClaim = useCallback(
    (form: EClaimFormParams) => {
      setIsClickVerify(true)
      setCheckModal(true)
      if (form?.amount) {
        refetch()
      }

      if (validationData?.message && checkModal) {
        setCheckModal(false)
        modalAction.show()
        setIsCheck(true)
      }
    }, [validationData])

  useEffect(() => {
    if (validationData?.message && checkModal) {
      modalAction.show()
      setCheckModal(false)
      setIsCheck(true)
    }
  }, [validationData, isClickVerify])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          modalAction.hide()
        }}
        onCancelText={t('newsDetail.modalButtonText')}
        hideCloseIcon
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          rowGap: 24,
          paddingTop: 24
        }}>
          <ErrorIcon src={IconError} />
          {
            claimItem.claimType.id === 1 ? (<><TextModal size={24} type="Bold">
              {i18n.language === 'en' ? validationData?.messageEn : validationData?.message}
            </TextModal>
              <TextModal>{i18n.language === 'en' ? validationData?.descriptionEn : validationData?.description}</TextModal> </>) :

              (
                <>
                  {
                    (!validationData?.status || !checkAccept) ? (
                      <>
                        <TextModal size={24} type="Bold">
                          {claimItem?.id === 22 ? (
                            validationData?.message === 'คุณขอกู้ไม่เกินสิทธิ' ? `${t('eClaim.provident.sixth')}` : `${t('eClaim.provident.seventh')}`
                          ) : i18n.language === 'en' ? validationData?.messageEn : validationData?.message}
                        </TextModal>
                        {
                          claimItem?.subClaims?.length > 0 ? <TextModal>{i18n.language === 'en' ? claimItem?.subClaims?.find((v: any) => v?.id === formValues?.subClaimId)?.descriptionEn : claimItem?.subClaims?.find((v: any) => v?.id === formValues?.subClaimId)?.description}</TextModal> :

                            <TextModal>{claimItem?.id === 22 ? `${t('eClaim.provident.ninth')}` : i18n.language === 'en' ? claimItem?.descriptionEn : claimItem?.description}</TextModal>
                        }
                      </>
                    ) : (
                      <>
                        <TextModal size={24} type="Bold">
                          {i18n.language === 'en' ? validationData?.messageEn : validationData?.message}
                        </TextModal>
                        {
                          claimItem?.subClaims?.length > 0 ? <TextModal>{i18n.language === 'en' ? claimItem?.subClaims?.find((v: any) => v?.id === formValues?.subClaimId)?.descriptionEn : claimItem?.subClaims?.find((v: any) => v?.id === formValues?.subClaimId)?.description}</TextModal> :
                            <TextModal>{claimItem?.id === 22 ? `${t('eClaim.provident.ten')}` : i18n.language === 'en' ? claimItem?.descriptionEn : claimItem?.description}</TextModal>
                        }
                      </>
                    )
                  }

                </>)
          }

        </div>
      </Modal>
      <ClaimFormContainer>
        <div style={{ backgroundColor: color.WHITE, borderRadius: '5px', padding: 8 }}>
          <Text size={24} type="Bold" style={{ paddingBottom: 32 }}>
            {editable ? `${t('eClaim.info.inputDetails')}` : `${t('eClaim.info.checkDetails')}`}
          </Text>
          <InputContainer>
            <FlexCol>
              <>
                <InputLabel size={22}>{t('eClaim.items')}</InputLabel>
                <Text type="Bold" size={20}>{i18n.language === 'en' ? claimItem?.nameEn : claimItem?.name}</Text>
              </>
            </FlexCol>

          </InputContainer>

          <InputContainer>
            <FlexCol>
              {editable && (
                <InputField
                  name="name"
                  label={t('eClaim.field.fullName')}
                  placeholder={getFullName}
                  disabled
                />
              )}
              {!editable && (
                <>  <InputLabel>{t('eClaim.field.fullName')}</InputLabel>
                  <Text type="Bold">{eClaimFormValue?.name}</Text></>
              )
              }
            </FlexCol>
          </InputContainer>

          {
            [20, 21].includes(claimItem?.id) && (
              <InputContainer>
                <FlexCol>
                  {editable && (
                    <InputField
                      name="userRemark"
                      label={claimItem?.id === 20 ? `${t('eClaim.field.claimFor2')}` : `${t('eClaim.field.claimFor')}`}
                    />
                  )}
                  {!editable && (
                    <>  <InputLabel>{claimItem?.id === 20 ? `${t('eClaim.field.claimFor2')}` : `${t('eClaim.field.claimFor')}`}</InputLabel>
                      <Text type="Bold">{eClaimFormValue?.userRemark}</Text></>
                  )
                  }
                </FlexCol>
              </InputContainer>
            )
          }

          {
            claimItem?.subClaims?.length > 0 && (
              <>
                <Text size={20}>
                  {t('app.typeOf')}{i18n.language === 'en' ? claimItem?.nameEn : claimItem?.name}
                </Text>
                <InputContainer>

                  <FlexCol>
                    {editable && (
                      <SelectField
                        name="subClaimId"
                        placeholder="ประเภท"
                        options={listSubClaims}
                      />
                    )}

                    {!editable && (
                      <Text type="Bold">{i18n.language === 'en' ? claimItem?.subClaims?.find((v: any) => v?.id === eClaimFormValue?.subClaimId)?.nameEn : claimItem?.subClaims?.find((v: any) => v?.id === eClaimFormValue?.subClaimId)?.name}</Text>
                    )}
                  </FlexCol>
                </InputContainer>
              </>
            )
          }

          <InputContainer>
            <FlexCol>
              {editable && (
                <DatePickerField
                  name="paymentDate"
                  label={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 17, 16, 19].includes(claimItem?.id) ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(claimItem?.id) ? `${t('eClaim.field.claimDate2')}` : `${t('eClaim.field.claimDate')}` : `${t('eClaim.field.receiptDate')}`}
                  validate={[requiredDate]}
                  disabled={disbleField}
                  maxDate={dayjs().endOf('day')}
                  minDate={dayjs().startOf('year')}
                />
              )}
              {!editable && <InputLabel>{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 17, 16, 19].includes(claimItem?.id) ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(claimItem?.id) ? `${t('eClaim.field.claimDate2')}` : `${t('eClaim.field.claimDate')}` : `${t('eClaim.field.receiptDate')}`}</InputLabel>}
              {!editable && (
                <Text type="Bold">{eClaimFormValue?.paymentDate?.format('DD/MM/YYYY')}</Text>
              )}
            </FlexCol>
          </InputContainer>


          {
            (claimItem?.id === 22 && editable) && (
              <>

                <h3>{t('eClaim.provident.first')}</h3>
                <InputContainer>
                  <FlexCol>
                    <Checkbox
                      className="check-box"
                      autoFocus={true}
                      checked={isAccept}
                      disabled={!editable}
                      onChange={(v) => {
                        setIsAccept((prev) => !prev)
                      }}
                    >
                      {t('eClaim.provident.second')}
                    </Checkbox>
                  </FlexCol>
                </InputContainer>

                <InputContainer>
                  <FlexCol>
                    <Checkbox
                      className="check-box"
                      autoFocus={true}
                      checked={isAccept2}
                      disabled={!editable}
                      onChange={(v) => {
                        setIsAccept2((prev) => !prev)
                      }}
                    >
                      {t('eClaim.provident.third')}
                    </Checkbox>
                  </FlexCol>
                </InputContainer>

                <InputContainer>
                  <FlexCol>
                    <Checkbox
                      className="check-box"
                      autoFocus={true}
                      checked={isAccept3}
                      disabled={!editable}
                      onChange={(v) => {
                        setIsAccept3((prev) => !prev)
                      }}
                    >
                      {t('eClaim.provident.fourth')}
                    </Checkbox>
                  </FlexCol>
                </InputContainer>

                <InputContainer>
                  <FlexCol>
                    <Checkbox
                      className="check-box"
                      autoFocus={true}
                      checked={isAccept4}
                      disabled={!editable}
                      onChange={(v) => {
                        setIsAccept4((prev) => !prev)
                      }}
                    >
                      {t('eClaim.provident.fifth')}
                    </Checkbox>
                  </FlexCol>
                </InputContainer></>
            )
          }


          <InputContainer>
            <FlexCol>
              {editable && (
                <InputField
                  name="amount"
                  label={[15, 16, 17, 19].includes(claimItem?.id) ? `${t('eClaim.field.amountEntitlement')}` : `${t('eClaim.field.amountReceipt')}`}
                  placeholder={t('app.inputAmount')}
                  validate={[required, isNotZero]}
                  // inputType="number"
                  disabled={claimItem.isDefaultValue}
                />
              )}
              {!editable && <InputLabel>{t('eClaim.field.amountReceipt')}</InputLabel>}
              {!editable && (
                <Text type="Bold">{numberWithCommas(eClaimFormValue?.amount.toString().replace(',', '') || 0)}</Text>
              )}
            </FlexCol>
            <Spacer />

            {
              (claimItem?.subClaims?.length > 0 && formValues?.subClaimId) && (
                <FlexCol>
                  {
                    editable && <OutlineButton onClick={handleSubmit(onSubmitCheckClaim)}>{t("eClaim.checkBalanceButton")}</OutlineButton>
                  }
                </FlexCol>
              )
            }


            {
              claimItem?.subClaims?.length === 0 && (
                <FlexCol>
                  {
                    editable && <OutlineButton onClick={handleSubmit(onSubmitCheckClaim)}>{t("eClaim.checkBalanceButton")}</OutlineButton>
                  }
                </FlexCol>
              )
            }
          </InputContainer>

          {
            ((!checkCurrentAmount && (checkAccept && isClickVerify) && (checkIsOnce && isCheck) && (formValues.paymentDate !== undefined)) || !editable) && (
              <>
                <Text size={24} type="Bold">
                  {t('main.attached')}
                </Text>

                {([20, 21].includes(claimItem?.id) || (claimItem.claimType.id === 1)) && (
                  <CheckButton onClick={onClickGetBill}>
                    {t('eClaim.infoForCheck')}
                  </CheckButton>
                )}
                {
                  claimItem?.claimDocument?.map((data: any, index: any) =>
                    <FileUploadContainer>
                      <UploadfileField
                        name={`files[claimDocumentTypeId=${data.documentType.id}]`}
                        icon={'receiptIcon'}
                        title={i18n.language === 'en' ? data.documentType.nameEn : data.documentType.name}
                        desc={t('app.attachmentSize')}
                        // value={eClaimFormValue?.citizenFileUrl}
                        isPreview={isPreview}
                        accept=".jpg,.png,.pdf"
                        // validate={validateImage}
                        maximumFileSize={3}
                      // disabled={disbleField}
                      />
                    </FileUploadContainer>
                  )
                }
              </>
            )
          }

        </div>
      </ClaimFormContainer>

      {
        editable ? (
          <FooterContent>
            {/* {
              (validationData !== undefined && !validationData?.status && (claimItem.claimType.id === 2 || checkCurrentAmount)) && <BackButton onClick={onClickBackToEnrollment}>
                {t('eClaim.homeButton')}
              </BackButton>
            } */}

            <BackButton onClick={onClickBackToEnrollment}>
              {t('eClaim.backButton')}
            </BackButton>

            <CustomEditableFooter>
              <SubmitButton onClick={handleSubmit(onSubmit)} disabled={invalid || !checkAttachtment || !checkAccept || !checkIsOnce || checkNotUndefind || checkCurrentAmount}>
                {t('eClaim.nextButton')}
              </SubmitButton>
            </CustomEditableFooter>

          </FooterContent>
        ) : (
          <FooterContent>
            <BackButton onClick={onClickBackToEnrollment}>
              {t('eClaim.backButton')}
            </BackButton>
            <CustomEditableFooter>
              <SubmitButton onClick={onSubmitApplication} disabled={isSubmitting || isUploading}>
                {t('eClaim.submitButton')}
              </SubmitButton>
            </CustomEditableFooter>
          </FooterContent>

        )
      }
    </>
  )
}

export default reduxForm<EClaimFormParams, EClaimParam, string>({
  form: ECLAIM_FORM_NAME,
  // initialValues: {
  //   relativeTypeId: 'ตัวเอง',
  // },
})(EClaimForm)
